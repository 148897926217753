import { SpinnerState } from './SpinnerCircle.enums';
import { Icon } from '../icon/Icon';
import { IconIdentifier, IconSize } from '../icon/Icon.enums';

import './SpinnerCircle.scss';

export type SpinnerCircleProps = {
	state?: SpinnerState
};

export function SpinnerCircle(props: SpinnerCircleProps) {
	const { state = SpinnerState.LOADING } = props;

	if (state === SpinnerState.LOADING) {
		return (
			<div className="spinner-circle">
				<div className="spinner-circle__animation">
				<span className="spinner-circle__animation__circle">
					<span className="spinner-circle__animation__circle--full">
						<svg xmlns="http://www.w3.org/2000/svg" width="152" height="152">
							<path d="M76 0C34 0 0 34 0 76s34 76 76 76 76-34 76-76S118 0 76 0zm0 8.1c37.5 0 67.9 30.4 67.9 67.9s-30.4 67.9-67.9 67.9S8.1 113.5 8.1 76 38.5 8.1 76 8.1z" />
						</svg>
					</span>
					<span className="spinner-circle__animation__circle--half">
						<svg xmlns="http://www.w3.org/2000/svg" width="152" height="152">
							<path d="M76 0c42 0 76 34 76 76s-34 76-76 76c-22.8 0-44-10.1-58.4-27.3-1.4-1.7-1.2-4.3.5-5.7 1.7-1.4 4.3-1.2 5.7.5 12.8 15.4 31.7 24.4 52.2 24.4 37.5 0 67.9-30.4 67.9-67.9S113.5 8.1 76 8.1c-2.2 0-4.1-1.8-4.1-4.1s1.9-4 4.1-4z" />
						</svg>
					</span>
					<span className="spinner-circle__logo">
						<img src="/assets/lib-oms-ui-primitive/spinner-circle/ABB_logo_big.svg" alt="ABB Logo" />
					</span>
				</span>
				</div>
			</div>
		);
	}
	return (
		<div className="spinner-circle">
			<span className="spinner-circle__done">
					<span className="spinner-circle__done__check">
						<Icon name={IconIdentifier.CHECK} size={IconSize.XLARGE} />
					</span>
				<span className="spinner-circle__done__inner" />
				<span className="spinner-circle__done__mid" />
				<span className="spinner-circle__done__outer" />
			</span>
		</div>
	);
}
