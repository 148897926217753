import React, { ReactNode } from 'react';

import './AppLayout.scss';

export type DashboardLayoutProps = {
	children: ReactNode
};

export function AppLayout(props: DashboardLayoutProps) {

	const { children } = props;

	return (
		<article className="app-layout">
			{children}
		</article>
	);
}
