import { ReactNode } from 'react';

import { buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import { ItemFlow } from './InputRadioGroup.enums';
import { LabelPosition } from '../../types/LabelPosition';

import './InputRadioGroup.scss';

export type InputRadioGroupProps = {
	label?: string,
	labelPosition?: LabelPosition,
	itemFlow?: ItemFlow,
	children: ReactNode
};

export function InputRadioGroup(props: InputRadioGroupProps) {
	const { label, children, labelPosition = LabelPosition.TOP, itemFlow = ItemFlow.VERTICAL } = props;

	return (
		<div className={`input-radio-group ${buildCssClassNameFromEnumValue(labelPosition, 'input-radio-group')}`}>
			<div className="input-radio-group__label">{label}</div>
			<div className={`input-radio-group__items ${buildCssClassNameFromEnumValue(itemFlow, 'input-radio-group__items')}`}>{children}</div>
		</div>
	);
}
