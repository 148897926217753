import { ReactNode } from 'react';

import './Topbar.scss';

export type TopbarProps = {
	children?: ReactNode
};

export function Topbar(props: TopbarProps) {

	const { children } = props;

	return (
		<header className="topbar">
			{children}
		</header>
	);
}
