import { ReactNode } from 'react';

import { ButtonGhost } from '../button-ghost/ButtonGhost';

import './ErrorFeedback.scss';

export type ErrorFeedbackAction = {
	label: string,
	onInvoke: () => void
};

export type ErrorFeedbackProps = {
	heading: string,
	message?: string,
	stack?: string,
	actions?: Array<ErrorFeedbackAction>
};

export function ErrorFeedback(props: ErrorFeedbackProps) {

	const { heading, message, actions = [], stack } = props;

	const renderMessage = (): ReactNode => {
		if (message === undefined) {
			return;
		}
		return (
			<p className="error-feedback__main__description">{message}</p>
		);
	};

	const renderStack = (): ReactNode => {
		if (stack === undefined) {
			return;
		}
		return (
			<pre className="error-feedback__main__stack">{stack}</pre>
		);
	};

	const renderActtions = (): ReactNode => {
		if (actions.length === 0) {
			return;
		}
		const actionButtons = actions.map((action, index) => {
			return (
				<ButtonGhost label={action.label} onClick={action.onInvoke} key={index} />
			);
		});
		return (
			<footer className="error-feedback__footer">
				{actionButtons}
			</footer>
		);
	};

	return (
		<article className="error-feedback">
			<header className="error-feedback__header">
				<img src="/assets/lib-oms-ui-primitive/images/error.svg" alt="error" />
			</header>
			<main className="error-feedback__main">
				<h1 className="error-feedback__main__heading">
					{heading}
				</h1>
				{renderMessage()}
				{renderStack()}
			</main>
			{renderActtions()}
		</article>
	);
}
