import React from 'react';

import { OauthEmitter, OauthEmitterProps } from './OauthEmitter';
import { OauthEmitterMock } from './OauthEmitterMock';

export function OauthEmitterFactory<AdditionalTokenResponse = unknown>(props: OauthEmitterProps<AdditionalTokenResponse>) {

	if (process.env['NX_SHARED_AUTH_EMITTER_MOCKED'] === 'true') {
		return (<OauthEmitterMock {...props} />);
	}
	return (<OauthEmitter {...props} />);

}
