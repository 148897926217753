import React, { ReactNode } from 'react';

import './InformationBoxGroup.scss';

export type InformationBoxProps = {
	children?: ReactNode
};


export const InformationBoxGroup = (props: InformationBoxProps) => {
	const {children} = props;

	return (
		<div className="information-box-group">
			{children}
		</div>
	);
};
