import { Separator } from '../separator/Separator';

import './SectionHeader.scss';

export type SectionHeaderProps = {
	heading: string
};

export function SectionHeader(props: SectionHeaderProps) {
	const { heading } = props;

	return (
		<header className="section-header">
			<div className="section-header__content">
				<h1 className="section-header__content__heading">{heading}</h1>
			</div>
			<Separator />
		</header>
	);
}
