import { ReactNode, useState } from 'react';

import { buildCssClassStringFromClassMap, buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import { NotificationLevel } from './Notification.enums';
import { ButtonIcon } from '../button-icon/ButtonIcon';
import { Icon } from '../icon/Icon';
import { IconIdentifier, IconSize } from '../icon/Icon.enums';

import './Notification.scss';

export type NotificationAction = {
	label: string,
	onInvoke: () => void
};

export type NotificationProps = {
	level?: NotificationLevel,
	heading: string,
	message?: string,
	details?: Array<string>,
	dismissable?: boolean,
	children?: ReactNode
};

export function Notification(props: NotificationProps) {
	const { level = NotificationLevel.INFO, heading, message, details = [], dismissable = true, children } = props;

	const [dismissed, setDismissed] = useState<boolean>(false);

	if (dismissed) {
		return null;
	}

	const handleDismiss = (): void => {
		setDismissed(true);
	};

	const renderMessage = (): ReactNode => {
		if (message === undefined) {
			return;
		}
		return (
			<p className="notification__content__header__description">
				{message}
			</p>
		);
	};

	const renderDetails = (): ReactNode => {
		if (details.length === 0) {
			return;
		}
		const detailListItems = details.map((detail, index): ReactNode => {
			return (
				<li className="notification__content__main__list__item" key={index}>
					{detail}
				</li>
			);
		});
		return (
			<main className="notification__content__main">
				<ul className="notification__content__main__list">
					{detailListItems}
				</ul>
			</main>
		);
	};

	const renderClose = () => {
		if (!dismissable) {
			return null;
		}
		return (
			<span className="notification__close">
				<ButtonIcon onClick={handleDismiss}>
					<Icon name={IconIdentifier.X} size={IconSize.SMALL} />
				</ButtonIcon>
			</span>
		);
	};

	const notificationFooterLinkClassMap = {
		'notification__content__footer': true,
		'notification__content__footer__link': true,
		'button-ghost': true,
		[buildCssClassNameFromEnumValue(level, 'notification__content__footer__link')]: true
	};

	return (
		<article className={`notification ${buildCssClassNameFromEnumValue(level, 'notification')}`}>
			<span className={`notification__color-bar ${buildCssClassNameFromEnumValue(level, 'notification__color-bar')}`} />
			<div className="notification__content">
				<header className="notification__content__header">
					<h1 className={`notification__content__header__heading ${buildCssClassNameFromEnumValue(level, 'notification__content__header__heading')}`}>
						{heading}
					</h1>
					{renderMessage()}
				</header>
				{renderDetails()}
				<footer className={buildCssClassStringFromClassMap(notificationFooterLinkClassMap)}>
					{children}
				</footer>
			</div>
			{renderClose()}
		</article>
	);
}
