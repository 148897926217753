import { ReactNode } from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import { NotificationBigStatus } from './NotificationBig.enums';

import './NotificationBig.scss';

export type NotificationBigProps = {
	status: NotificationBigStatus,
	heading: string,
	message?: string,
	subline?: string,
	mobile?: boolean,
	children?: ReactNode
};

export const NotificationBig = (props: NotificationBigProps) => {
	const { status, heading, message, subline, mobile = false, children = [] } = props;

	// conditional classNames
	const notificationBigClassMap = {
		'notification-big': true,
		[`notification-big--${status}`]: NotificationBigStatus.ERROR,
		[`notification-big--${status}`]: NotificationBigStatus.NO_APPOINTMENT && mobile
	};

	const notificationBigHeadingClassMap = {
		'notification-big__content__header__heading': true,
		[`notification-big__content__header__heading--${status}`]: NotificationBigStatus.ERROR
	};

	//render functions
	const renderMessage = (): ReactNode => {
		if (message === undefined) {
			return;
		}
		return (
			<p className="notification-big__content__header__description">
				{message}
			</p>
		);
	};

	const renderSubline = (): ReactNode => {
		if (subline === undefined) {
			return;
		}
		return (
			<p className="notification-big__content__header__subline">
				Message was: {subline}
			</p>
		);
	};

	const renderImage = () => {
		return (
			<img src={`../../assets/lib-oms-ui-primitive/images/${status}.svg`} alt={status} />
		);
	};

	return (
		<article className={buildCssClassStringFromClassMap(notificationBigClassMap)}>
			<div className="notification-big__content">
				<header className="notification-big__content__header">
					<h1 className={buildCssClassStringFromClassMap(notificationBigHeadingClassMap)}>
						{heading}
					</h1>
				</header>
				{renderMessage()}
				{renderSubline()}
				<div className="notification-big__content__actions">
					{children}
				</div>
			</div>
			<div className="notification-big__img">
				{renderImage()}
			</div>
		</article>
	);
};
