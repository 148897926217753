import { MouseEvent } from 'react';
import { useNavigate, matchPath, useLocation } from 'react-router-dom';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier, IconSize } from '../icon/Icon.enums';
import { Pill } from '../pill/Pill';

import './NavigationItem.scss';

export type NavigationItemProps<T extends string = string> = {
	icon: IconIdentifier,
	label: string,
	to: string,
	pill?: string,
	highlightPaths?: Array<T>
};

export function NavigationItem<T extends string = string>(props: NavigationItemProps<T>) {

	const { to, label, pill, icon, highlightPaths } = props;

	const location = useLocation();
	const navigate = useNavigate();

	const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		navigate(to);
	};

	let active = false;
	if (highlightPaths !== undefined) {
		for (const highlightPath of highlightPaths) {
			if (matchPath(highlightPath, location.pathname)) {
				active = true;
				break;
			}
		}
	}

	const cssClassMap = {
		'navigation-item': true,
		'navigation-item--active': active
	};

	return (
		<a href={to} onClick={handleClick} className={buildCssClassStringFromClassMap(cssClassMap)}>
			<span className="navigation-item__icon">
				<Icon size={IconSize.MEDIUM} name={icon} />
			</span>
			<span className="navigation-item__label">
				{label}
			</span>
			<Pill value={pill} />
		</a>
	);

}
