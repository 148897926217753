import { ChangeEvent, InputHTMLAttributes } from 'react';

import { buildCssClassStringFromClassMap, buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import { LabelPosition } from '../../types/LabelPosition';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './InputText.scss';

export type InputTextProps = {
	type?: 'color' | 'date' | 'datetime-local' | 'email' | 'hidden' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url' | 'week',
	label?: string,
	labelPosition?: LabelPosition,
	icon?: IconIdentifier,
	onChange?: (value: string) => void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type'>;

export function InputText(props: InputTextProps) {
	const {
		type = 'text',
		label,
		labelPosition = LabelPosition.TOP,
		icon,
		onChange = null,
		disabled,
		readOnly,
		...rest
	} = props;

	const labelClassMap = {
		'input-text__label': true,
		'input-text__label--disabled': disabled,
		'input-text__label--readonly': readOnly
	};

	const inputTextFieldClassMap = {
		'input-text__field__input': true,
		'input-text__field__input--readonly': readOnly
	};

	// event handling
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (onChange !== null) {
			onChange(event.target.value);
		}
	};

	// render functions
	const renderLabel = () => {
		if (label === undefined) {
			return null;
		}
		return (
			<span className={`input-text__label ${buildCssClassStringFromClassMap(labelClassMap)} ${buildCssClassNameFromEnumValue(labelPosition, 'input-text__label')}`}>
				{label}
			</span>
		);
	};

	const renderIcon = () => {
		if (icon === undefined) {
			return;
		}
		return (
			<span className="input-text__field__icon">
				<Icon name={icon} />
			</span>
		);
	};

	return (
		<label className={`input-text ${buildCssClassNameFromEnumValue(labelPosition, 'input-text')}`}>
			{renderLabel()}
			<span className="input-text__field">
				<input
					type={type}
					onChange={handleChange}
					className={`input-text__field__input ${buildCssClassStringFromClassMap(inputTextFieldClassMap)} ${buildCssClassNameFromEnumValue(labelPosition, 'input-text__field__input')}`}
					disabled={disabled}
					{...rest}
				/>
				{renderIcon()}
			</span>
		</label>
	);
}
