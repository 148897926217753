import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { FilterCriteria, SingleValueFilterComparator, SortCriteria, SortDirection } from '@abb-emobility/shared/api-integration-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

export const createSortCriteria = (): SortCriteria<InstallerAppointmentModel> => {
	return [
		{
			property: 'periodStartsAt',
			direction: SortDirection.ASCENDING
		}
	];
};

export const createFilterCriteria = (orderId: ModelPrimaryKey): FilterCriteria<InstallerAppointmentModel> => {
	return [
		{
			id: 'orderId',
			criteria: [
				{
					property: 'orderId',
					comparator: SingleValueFilterComparator.EQUAL,
					value: orderId
				}
			]
		}
	];
};
