import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthProviderInterceptingProps } from '@abb-emobility/shared/auth-provider';
import { useSearchParams } from '@abb-emobility/shared/browser';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import {
	KeycloakApiClientFactory,
	OAUTH_REDIRECT_ONE_TIME_CODE_PARAM,
	OAUTH_REDIRECT_SESSION_STATE_PARAM,
	TokenResponseModel
} from '@abb-emobility/shared/keycloak-integration';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	AppLayout,
	AppLayoutMain,
	AppLayoutMainContent,
	AppLayoutMainHeader,
	ErrorFeedback,
	ErrorFeedbackAction,
	SpinnerCircle,
	Topbar
} from '@abb-emobility/shared/ui-primitive';
import { Optional } from '@abb-emobility/shared/util';

enum OauthAction {
	IDLE = 'IDLE',
	REQUEST_TOKEN = 'REQUEST_TOKEN',
	DONE = 'DONE',
	FAILED = 'FAILED'
}

export type OauthEmitterProps<AdditionalTokenResponse> = {
	onTokenReceived?: (oauthTokenResponse: TokenResponseModel & AdditionalTokenResponse) => void
} & AuthProviderInterceptingProps;

export function OauthEmitter<AdditionalTokenResponse = unknown>(props: OauthEmitterProps<AdditionalTokenResponse>) {

	const { onAuthenticate = null, onTokenReceived = null } = props;

	const env = useEnv();
	const location = useLocation();
	const searchParams = useSearchParams();

	const oauthBaseUrl = new Optional(process.env['NX_KEYCLOAK_API_BASE_URL'])
		.getOrThrow(new AppError('Oauth base URL unavailable'));
	const oauthRealm = env.get<string>('oauthRealm').getOrThrow(new AppError('Oauth realm unavailable'));
	const oauthClientId = env.get<string>('oauthClientId').getOrThrow(new AppError('Oauth client id unavailable'));

	const redirectUrl = new URL(window.location.href);
	redirectUrl.pathname = location.pathname;
	redirectUrl.hash = location.hash;

	const redirectSearchParams: Record<string, string> = {};
	const searchParamsMap = searchParams.all();
	for (const searchParamKey of searchParamsMap.keys()) {
		if (
			searchParamKey !== OAUTH_REDIRECT_ONE_TIME_CODE_PARAM
			&& searchParamKey !== OAUTH_REDIRECT_SESSION_STATE_PARAM
		) {
			const searchParamValue = searchParamsMap.get(searchParamKey);
			if (searchParamValue === undefined) {
				continue;
			}
			redirectSearchParams[searchParamKey] = searchParamValue;
		}
	}

	redirectUrl.search = new URLSearchParams(redirectSearchParams).toString();

	const l10n = useL10n();

	const [authAction, setAuthAction] = useState<OauthAction>(OauthAction.IDLE);
	const tokenRequested = useRef<boolean>(false);

	const oauthCode = searchParams.get(OAUTH_REDIRECT_ONE_TIME_CODE_PARAM);
	if (oauthCode !== null && authAction === OauthAction.IDLE) {
		setAuthAction(OauthAction.REQUEST_TOKEN);
	}

	const handleRetry = (): void => {
		KeycloakApiClientFactory.create().login(oauthBaseUrl, oauthRealm, oauthClientId, redirectUrl.toString());
	};

	useEffect((): void => {
		const processAuth = async (): Promise<void> => {
			switch (authAction) {
				case OauthAction.IDLE:
					// Redirect to the login URL
					KeycloakApiClientFactory.create().login(oauthBaseUrl, oauthRealm, oauthClientId, redirectUrl.toString());
					break;
				case OauthAction.REQUEST_TOKEN:
					// Perform accessToken request
					try {
						if (tokenRequested.current) {
							return;
						}
						tokenRequested.current = true;
						const tokenResponse = await KeycloakApiClientFactory.create().requestToken<AdditionalTokenResponse>(
							oauthBaseUrl,
							oauthRealm,
							oauthClientId,
							redirectUrl.toString(),
							oauthCode ?? ''
						);
						if (onAuthenticate !== null) {
							const accessTokenValidTo = new Date();
							accessTokenValidTo.setSeconds(accessTokenValidTo.getSeconds() + tokenResponse.expires_in);
							const refreshTokenValidTo = new Date();
							refreshTokenValidTo.setSeconds(refreshTokenValidTo.getSeconds() + tokenResponse.refresh_expires_in);
							onAuthenticate(
								tokenResponse.access_token,
								accessTokenValidTo,
								tokenResponse.refresh_token,
								refreshTokenValidTo,
								tokenResponse.roles
							);
						}
						if (onTokenReceived !== null) {
							onTokenReceived(tokenResponse);
						}
						setAuthAction(OauthAction.DONE);

						const url = new URL(window.location.href);
						url.searchParams.delete(OAUTH_REDIRECT_ONE_TIME_CODE_PARAM);
						url.searchParams.delete(OAUTH_REDIRECT_SESSION_STATE_PARAM);
						window.history.replaceState(null, '', url.toString());
					} catch (error) {
						setAuthAction(OauthAction.FAILED);
					}
					break;
			}
		};
		void processAuth();
	}, [authAction]);

	const renderMain = (): ReactNode => {
		switch (authAction) {
			case OauthAction.FAILED: {
				const reloadAction: ErrorFeedbackAction = {
					label: l10n.translate('sharedUiAuthEmitter.error.oauth.retry.label'),
					onInvoke: handleRetry
				};
				return (
					<ErrorFeedback
						heading={l10n.translate('sharedUiAuthEmitter.error.oauth.heading')}
						message={l10n.translate('sharedUiAuthEmitter.error.oauth.message')}
						actions={[reloadAction]}
					/>
				);
			}
			default:
				return (<SpinnerCircle />);
		}
	};

	return (
		<AppLayout>
			<AppLayoutMain>
				<AppLayoutMainHeader>
					<Topbar />
				</AppLayoutMainHeader>
				<AppLayoutMainContent>
					{renderMain()}
				</AppLayoutMainContent>
			</AppLayoutMain>
		</AppLayout>
	);

}
