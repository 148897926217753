import React, { Suspense } from 'react';

import { IconIdentifier, IconSize } from './Icon.enums';

const IconSvg = React.lazy(() => import('./IconSvg'));

export type IconProps = {
	name: IconIdentifier,
	size?: IconSize
};

export function Icon(props: IconProps) {

	const { name, size } = props;
	// const { name, size = IconSize.MEDIUM } = props;

	return (
		<Suspense fallback={null}>
			<IconSvg name={name} size={size}/>
		</Suspense>
	);

}
