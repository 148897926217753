import React from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './Pill.scss';

type PillProps = {
	value?: string,
	disabled?: boolean
};

export const Pill = (props: PillProps) => {
	const { value, disabled = false } = props;

	const pillClassMap = {
		'pill': true,
		'pill--disabled': disabled
	};

	return (
		<span className={buildCssClassStringFromClassMap(pillClassMap)}>
			{value}
		</span>
	);
};
