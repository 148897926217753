import { AnyInstallationPartnerOrderInformationCollectionItemModel } from '@abb-emobility/oms/domain-model';
import { FilterCriteria, SingleValueFilterComparator, SortCriteria, SortDirection } from '@abb-emobility/shared/api-integration-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

export const createSortCriteria = (): SortCriteria<AnyInstallationPartnerOrderInformationCollectionItemModel> => {
	return [
		{ property: 'createdAt', direction: SortDirection.ASCENDING }
	];
};

export const createFilterCriteria = (orderId: ModelPrimaryKey): FilterCriteria<AnyInstallationPartnerOrderInformationCollectionItemModel> => {
	return [{
		id: 'ORDER_ID',
		criteria: [{
			property: 'orderId',
			comparator: SingleValueFilterComparator.EQUAL,
			value: orderId
		}]
	}];
};
