import { InputHTMLAttributes } from 'react';

import './InputRadio.scss';

export type InputRadioProps = {
	label: string
} & InputHTMLAttributes<HTMLInputElement>;

export function InputRadio(props: InputRadioProps) {
	const { label, ...rest } = props;

	return (
		<label className="input-radio">
			<span className="input-radio__input">
				<input type="radio" {...rest} />
			</span>
			<span className="input-radio__label">{label}</span>
		</label>
	);
}
