import React, { ReactNode } from 'react';

import { Navigation } from '../../navigation/Navigation';

import './AppLayoutNavigation.scss';

export type AppLayoutNavigationProps = {
	children: ReactNode
};

export function AppLayoutNavigation(props: AppLayoutNavigationProps) {

	const { children } = props;

	return (
		<aside className="app-layout-navigation">
			<Navigation>
				{children}
			</Navigation>
		</aside>
	);
}
