export enum IconSize {
	SMALL = 'SMALL',
	MEDIUM = 'MEDIUM',
	LARGE = 'LARGE',
	XLARGE = 'XLARGE',
}

/*
* Icon Identifier based on https://phosphoricons.com/
* */
export enum IconIdentifier {
	ACTIVITY = 'activity',
	ADDRESS_BOOK = 'address-book',
	AIRPLANE_IN_FLIGHT = 'airplane-in-flight',
	AIRPLANE_LANDING = 'airplane-landing',
	AIRPLANE_TAKEOFF = 'airplane-takeoff',
	AIRPLANE_TILT = 'airplane-tilt',
	AIRPLANE = 'airplane',
	AIRPLAY = 'airplay',
	ALARM = 'alarm',
	ALIEN = 'alien',
	ALIGN_BOTTOM_SIMPLE = 'align-bottom-simple',
	ALIGN_BOTTOM = 'align-bottom',
	ALIGN_CENTER_HORIZONTAL_SIMPLE = 'align-center-horizontal-simple',
	ALIGN_CENTER_HORIZONTAL = 'align-center-horizontal',
	ALIGN_CENTER_VERTICAL_SIMPLE = 'align-center-vertical-simple',
	ALIGN_CENTER_VERTICAL = 'align-center-vertical',
	ALIGN_LEFT_SIMPLE = 'align-left-simple',
	ALIGN_LEFT = 'align-left',
	ALIGN_RIGHT_SIMPLE = 'align-right-simple',
	ALIGN_RIGHT = 'align-right',
	ALIGN_TOP_SIMPLE = 'align-top-simple',
	ALIGN_TOP = 'align-top',
	ANCHOR_SIMPLE = 'anchor-simple',
	ANCHOR = 'anchor',
	ANDROID_LOGO = 'android-logo',
	ANGULAR_LOGO = 'angular-logo',
	APERTURE = 'aperture',
	APP_STORE_LOGO = 'app-store-logo',
	APP_WINDOW = 'app-window',
	APPLE_LOGO = 'apple-logo',
	APPLE_PODCASTS_LOGO = 'apple-podcasts-logo',
	ARCHIVE_BOX = 'archive-box',
	ARCHIVE_TRAY = 'archive-tray',
	ARCHIVE = 'archive',
	ARMCHAIR = 'armchair',
	ARROW_ARC_LEFT = 'arrow-arc-left',
	ARROW_ARC_RIGHT = 'arrow-arc-right',
	ARROW_BEND_DOUBLE_UP_LEFT = 'arrow-bend-double-up-left',
	ARROW_BEND_DOUBLE_UP_RIGHT = 'arrow-bend-double-up-right',
	ARROW_BEND_DOWN_LEFT = 'arrow-bend-down-left',
	ARROW_BEND_DOWN_RIGHT = 'arrow-bend-down-right',
	ARROW_BEND_LEFT_DOWN = 'arrow-bend-left-down',
	ARROW_BEND_LEFT_UP = 'arrow-bend-left-up',
	ARROW_BEND_RIGHT_DOWN = 'arrow-bend-right-down',
	ARROW_BEND_RIGHT_UP = 'arrow-bend-right-up',
	ARROW_BEND_UP_LEFT = 'arrow-bend-up-left',
	ARROW_BEND_UP_RIGHT = 'arrow-bend-up-right',
	ARROW_CIRCLE_DOWN_LEFT = 'arrow-circle-down-left',
	ARROW_CIRCLE_DOWN_RIGHT = 'arrow-circle-down-right',
	ARROW_CIRCLE_DOWN = 'arrow-circle-down',
	ARROW_CIRCLE_LEFT = 'arrow-circle-left',
	ARROW_CIRCLE_RIGHT = 'arrow-circle-right',
	ARROW_CIRCLE_UP_LEFT = 'arrow-circle-up-left',
	ARROW_CIRCLE_UP_RIGHT = 'arrow-circle-up-right',
	ARROW_CIRCLE_UP = 'arrow-circle-up',
	ARROW_CLOCKWISE = 'arrow-clockwise',
	ARROW_COUNTER_CLOCKWISE = 'arrow-counter-clockwise',
	ARROW_DOWN_LEFT = 'arrow-down-left',
	ARROW_DOWN_RIGHT = 'arrow-down-right',
	ARROW_DOWN = 'arrow-down',
	ARROW_ELBOW_DOWN_LEFT = 'arrow-elbow-down-left',
	ARROW_ELBOW_DOWN_RIGHT = 'arrow-elbow-down-right',
	ARROW_ELBOW_LEFT_DOWN = 'arrow-elbow-left-down',
	ARROW_ELBOW_LEFT_UP = 'arrow-elbow-left-up',
	ARROW_ELBOW_LEFT = 'arrow-elbow-left',
	ARROW_ELBOW_RIGHT_DOWN = 'arrow-elbow-right-down',
	ARROW_ELBOW_RIGHT_UP = 'arrow-elbow-right-up',
	ARROW_ELBOW_RIGHT = 'arrow-elbow-right',
	ARROW_ELBOW_UP_LEFT = 'arrow-elbow-up-left',
	ARROW_ELBOW_UP_RIGHT = 'arrow-elbow-up-right',
	ARROW_FAT_DOWN = 'arrow-fat-down',
	ARROW_FAT_LEFT = 'arrow-fat-left',
	ARROW_FAT_LINE_DOWN = 'arrow-fat-line-down',
	ARROW_FAT_LINE_LEFT = 'arrow-fat-line-left',
	ARROW_FAT_LINE_RIGHT = 'arrow-fat-line-right',
	ARROW_FAT_LINES_DOWN = 'arrow-fat-lines-down',
	ARROW_FAT_LINES_LEFT = 'arrow-fat-lines-left',
	ARROW_FAT_LINES_RIGHT = 'arrow-fat-lines-right',
	ARROW_FAT_LINES_UP = 'arrow-fat-lines-up',
	ARROW_FAT_RIGHT = 'arrow-fat-right',
	ARROW_FAT_UP = 'arrow-fat-up',
	ARROW_LEFT = 'arrow-left',
	ARROW_LINE_DOWN_LEFT = 'arrow-line-down-left',
	ARROW_LINE_DOWN_RIGHT = 'arrow-line-down-right',
	ARROW_LINE_DOWN = 'arrow-line-down',
	ARROW_LINE_LEFT = 'arrow-line-left',
	ARROW_LINE_RIGHT = 'arrow-line-right',
	ARROW_LINE_UP_LEFT = 'arrow-line-up-left',
	ARROW_LINE_UP_RIGHT = 'arrow-line-up-right',
	ARROW_LINE_UP = 'arrow-line-up',
	ARROW_RIGHT = 'arrow-right',
	ARROW_SQUARE_DOWN_LEFT = 'arrow-square-down-left',
	ARROW_SQUARE_DOWN_RIGHT = 'arrow-square-down-right',
	ARROW_SQUARE_DOWN = 'arrow-square-down',
	ARROW_SQUARE_IN = 'arrow-square-in',
	ARROW_SQUARE_LEFT = 'arrow-square-left',
	ARROW_SQUARE_OUT = 'arrow-square-out',
	ARROW_SQUARE_RIGHT = 'arrow-square-right',
	ARROW_SQUARE_UP_LEFT = 'arrow-square-up-left',
	ARROW_SQUARE_UP_RIGHT = 'arrow-square-up-right',
	ARROW_SQUARE_UP = 'arrow-square-up',
	ARROW_U_DOWN_LEFT = 'arrow-u-down-left',
	ARROW_U_DOWN_RIGHT = 'arrow-u-down-right',
	ARROW_U_LEFT_DOWN = 'arrow-u-left-down',
	ARROW_U_LEFT_UP = 'arrow-u-left-up',
	ARROW_U_RIGHT_DOWN = 'arrow-u-right-down',
	ARROW_U_RIGHT_UP = 'arrow-u-right-up',
	ARROW_U_UP_LEFT = 'arrow-u-up-left',
	ARROW_U_UP_RIGHT = 'arrow-u-up-right',
	ARROW_UP_LEFT = 'arrow-up-left',
	ARROW_UP_RIGHT = 'arrow-up-right',
	ARROW_UP = 'arrow-up',
	ARROWS_CLOCKWISE = 'arrows-clockwise',
	ARROWS_COUNTER_CLOCKWISE = 'arrows-counter-clockwise',
	ARROWS_DOWN_UP = 'arrows-down-up',
	ARROWS_HORIZONTAL = 'arrows-horizontal',
	ARROWS_IN_CARDINAL = 'arrows-in-cardinal',
	ARROWS_IN_LINE_HORIZONTAL = 'arrows-in-line-horizontal',
	ARROWS_IN_LINE_VERTICAL = 'arrows-in-line-vertical',
	ARROWS_IN_SIMPLE = 'arrows-in-simple',
	ARROWS_IN = 'arrows-in',
	ARROWS_LEFT_RIGHT = 'arrows-left-right',
	ARROWS_OUT_CARDINAL = 'arrows-out-cardinal',
	ARROWS_OUT_LINE_HORIZONTAL = 'arrows-out-line-horizontal',
	ARROWS_OUT_LINE_VERTICAL = 'arrows-out-line-vertical',
	ARROWS_OUT_SIMPLE = 'arrows-out-simple',
	ARROWS_OUT = 'arrows-out',
	ARROWS_VERTICAL = 'arrows-vertical',
	ARTICLE_MEDIUM = 'article-medium',
	ARTICLE_NY_TIMES = 'article-ny-times',
	ARTICLE = 'article',
	ASTERISK_SIMPLE = 'asterisk-simple',
	ASTERISK = 'asterisk',
	AT = 'at',
	ATOM = 'atom',
	BABY = 'baby',
	BACKPACK = 'backpack',
	BACKSPACE = 'backspace',
	BAG_SIMPLE = 'bag-simple',
	BAG = 'bag',
	BALLON = 'ballon',
	BANDAIDS = 'bandaids',
	BANK = 'bank',
	BARBELL = 'barbell',
	BARCODE = 'barcode',
	BARRICADE = 'barricade',
	BASEBALL = 'baseball',
	BASKETBALL = 'basketball',
	BATHTUB = 'bathtub',
	BATTERY_CHARGING_VERTICAL = 'battery-charging-vertical',
	BATTERY_CHARGING = 'battery-charging',
	BATTERY_EMPTY = 'battery-empty',
	BATTERY_FULL = 'battery-full',
	BATTERY_HIGH = 'battery-high',
	BATTERY_LOW = 'battery-low',
	BATTERY_MEDIUM = 'battery-medium',
	BATTERY_PLUS = 'battery-plus',
	BATTERY_WARNING_VERTICAL = 'battery-warning-vertical',
	BATTERY_WARNING = 'battery-warning',
	BED = 'bed',
	BEER_BOTTLE = 'beer-bottle',
	BEHANCE_LOGO = 'behance-logo',
	BELL_RINGING = 'bell-ringing',
	BELL_SIMPLE_RINGING = 'bell-simple-ringing',
	BELL_SIMPLE_SLASH = 'bell-simple-slash',
	BELL_SIMPLE_Z = 'bell-simple-z',
	BELL_SIMPLE = 'bell-simple',
	BELL_SLASH = 'bell-slash',
	BELL_Z = 'bell-z',
	BELL = 'bell',
	BEZIER_CURVE = 'bezier-curve',
	BICYCLE = 'bicycle',
	BINOCULARS = 'binoculars',
	BIRD = 'bird',
	BLUETOOTH_CONNECTED = 'bluetooth-connected',
	BLUETOOTH_SLASH = 'bluetooth-slash',
	BLUETOOTH_X = 'bluetooth-x',
	BLUETOOTH = 'bluetooth',
	BOAT = 'boat',
	BOOK_BOOKMARK = 'book-bookmark',
	BOOK_OPEN = 'book-open',
	BOOK = 'book',
	BOOKMARK_SIMPLE = 'bookmark-simple',
	BOOKMARK = 'bookmark',
	BOOKMARKS_SIMPLE = 'bookmarks-simple',
	BOOKMARKS = 'bookmarks',
	BOOKS = 'books',
	BOUNDING_BOX = 'bounding-box',
	BRACKETS_ANGLE = 'brackets-angle',
	BRACKETS_CURLY = 'brackets-curly',
	BRACKETS_ROUND = 'brackets-round',
	BRACKETS_SQUARE = 'brackets-square',
	BRAIN = 'brain',
	BRANDY = 'brandy',
	BRIEFCASE_METAL = 'briefcase-metal',
	BRIEFCASE = 'briefcase',
	BROADCAST = 'broadcast',
	BROWSER = 'browser',
	BROWSERS = 'browsers',
	BUG_BEETLE = 'bug-beetle',
	BUG_DROID = 'bug-droid',
	BUG = 'bug',
	BUILDINGS = 'buildings',
	BUS = 'bus',
	BUTTERFLY = 'butterfly',
	CACTUS = 'cactus',
	CAKE = 'cake',
	CALCULATOR = 'calculator',
	CALENDAR_BLANK = 'calendar-blank',
	CALENDAR_CHECK = 'calendar-check',
	CALENDAR_PLUS = 'calendar-plus',
	CALENDAR_X = 'calendar-x',
	CALENDAR = 'calendar',
	CAMERA_ROTATE = 'camera-rotate',
	CAMERA_SLASH = 'camera-slash',
	CAMERA = 'camera',
	CAMPFIRE = 'campfire',
	CAR_SIMPLE = 'car-simple',
	CAR = 'car',
	CARDHOLDER = 'cardholder',
	CARDS = 'cards',
	CARET_CIRCLE_DOUBLE_DOWN = 'caret-circle-double-down',
	CARET_CIRCLE_DOUBLE_LEFT = 'caret-circle-double-left',
	CARET_CIRCLE_DOUBLE_RIGHT = 'caret-circle-double-right',
	CARET_CIRCLE_DOUBLE_UP = 'caret-circle-double-up',
	CARET_CIRCLE_DOWN = 'caret-circle-down',
	CARET_CIRCLE_LEFT = 'caret-circle-left',
	CARET_CIRCLE_RIGHT = 'caret-circle-right',
	CARET_CIRCLE_UP = 'caret-circle-up',
	CARET_DOUBLE_DOWN = 'caret-double-down',
	CARET_DOUBLE_LEFT = 'caret-double-left',
	CARET_DOUBLE_RIGHT = 'caret-double-right',
	CARET_DOUBLE_UP = 'caret-double-up',
	CARET_DOWN = 'caret-down',
	CARET_LEFT = 'caret-left',
	CARET_RIGHT = 'caret-right',
	CARET_UP = 'caret-up',
	CAT = 'cat',
	CELL_SIGNAL_FULL = 'cell-signal-full',
	CELL_SIGNAL_HIGH = 'cell-signal-high',
	CELL_SIGNAL_LOW = 'cell-signal-low',
	CELL_SIGNAL_MEDIUM = 'cell-signal-medium',
	CELL_SIGNAL_NONE = 'cell-signal-none',
	CELL_SIGNAL_SLASH = 'cell-signal-slash',
	CELL_SIGNAL_X = 'cell-signal-x',
	CHALKBOARD_SIMPLE = 'chalkboard-simple',
	CHALKBOARD_TEACHER = 'chalkboard-teacher',
	CHALKBOARD = 'chalkboard',
	CHART_BAR = 'chart-bar',
	CHART_LINE_UP = 'chart-line-up',
	CHART_LINE = 'chart-line',
	CHART_PIE_SLICE = 'chart-pie-slice',
	CHART_PIE = 'chart-pie',
	CHAT_CENTERED_DOTS = 'chat-centered-dots',
	CHAT_CENTERED_TEXT = 'chat-centered-text',
	CHAT_CENTERED = 'chat-centered',
	CHAT_CIRCLE_DOTS = 'chat-circle-dots',
	CHAT_CIRCLE_TEXT = 'chat-circle-text',
	CHAT_CIRCLE = 'chat-circle',
	CHAT_DOTS = 'chat-dots',
	CHAT_TEARDROP_DOTS = 'chat-teardrop-dots',
	CHAT_TEARDROP_TEXT = 'chat-teardrop-text',
	CHAT_TEARDROP = 'chat-teardrop',
	CHAT_TEXT = 'chat-text',
	CHAT = 'chat',
	CHATS_CIRCLE = 'chats-circle',
	CHATS_TEARDROP = 'chats-teardrop',
	CHATS = 'chats',
	CHECK_CIRCLE = 'check-circle',
	CHECK_SQUARE_OFFSET = 'check-square-offset',
	CHECK_SQUARE = 'check-square',
	CHECK = 'check',
	CHECKS = 'checks',
	CIRCLE_DASHED = 'circle-dashed',
	CIRCLE_HALF_TILT = 'circle-half-tilt',
	CIRCLE_HALF = 'circle-half',
	CIRCLE_NOTCH = 'circle-notch',
	CIRCLE_WAVY_QUESTION = 'circle-wavy-question',
	CIRCLE_WAVY_WARNING = 'circle-wavy-warning',
	CIRCLE_WAVY = 'circle-wavy',
	CIRCLE = 'circle',
	CIRCLES_FOUR = 'circles-four',
	CIRCLES_THREE_PLUS = 'circles-three-plus',
	CIRCLES_THREE = 'circles-three',
	CLIPBOARD_TEXT = 'clipboard-text',
	CLIPBOARD = 'clipboard',
	CLOCK_AFTERNOON = 'clock-afternoon',
	CLOCK_CLOCKWISE = 'clock-clockwise',
	CLOCK_COUNTER_CLOCKWISE = 'clock-counter-clockwise',
	CLOCK = 'clock',
	CLOSED_CAPTIONING = 'closed-captioning',
	CLOUD_ARROW_DOWN = 'cloud-arrow-down',
	CLOUD_ARROW_UP = 'cloud-arrow-up',
	CLOUD_CHECK = 'cloud-check',
	CLOUD_FOG = 'cloud-fog',
	CLOUD_LIGHTNING = 'cloud-lightning',
	CLOUD_MOON = 'cloud-moon',
	CLOUD_RAIN = 'cloud-rain',
	CLOUD_SLASH = 'cloud-slash',
	CLOUD_SNOW = 'cloud-snow',
	CLOUD_SUN = 'cloud-sun',
	CLOUD = 'cloud',
	COAT_HANGER = 'coat-hanger',
	CODE_SIMPLE = 'code-simple',
	CODE = 'code',
	CODEPEN_LOGO = 'codepen-logo',
	CODESANDBOX_LOGO = 'codesandbox-logo',
	COFFEE = 'coffee',
	COIN_VERTICAL = 'coin-vertical',
	COIN = 'coin',
	COINS = 'coins',
	COLUMNS = 'columns',
	COMMAND = 'command',
	COMPASS = 'compass',
	COMPUTER_TOWER = 'computer-tower',
	CONFETTI = 'confetti',
	COOKIE = 'cookie',
	COOKING_POT = 'cooking-pot',
	COPY_SIMPLE = 'copy-simple',
	COPY = 'copy',
	COPYLEFT = 'copyleft',
	COPYRIGHT = 'copyright',
	CORNERS_IN = 'corners-in',
	CORNERS_OUT = 'corners-out',
	CPU = 'cpu',
	CREDIT_CARD = 'credit-card',
	CROP = 'crop',
	CROSSHAIR_SIMPLE = 'crosshair-simple',
	CROSSHAIR = 'crosshair',
	CROWN_SIMPLE = 'crown-simple',
	CROWN = 'crown',
	CUBE = 'cube',
	CURRENCY_BTC = 'currency-btc',
	CURRENCY_CIRCLE_DOLLAR = 'currency-circle-dollar',
	CURRENCY_CNY = 'currency-cny',
	CURRENCY_DOLLAR_SIMPLE = 'currency-dollar-simple',
	CURRENCY_DOLLAR = 'currency-dollar',
	CURRENCY_ETH = 'currency-eth',
	CURRENCY_EUR = 'currency-eur',
	CURRENCY_GBP = 'currency-gbp',
	CURRENCY_INR = 'currency-inr',
	CURRENCY_JPY = 'currency-jpy',
	CURRENCY_KRW = 'currency-krw',
	CURRENCY_KZT = 'currency-kzt',
	CURRENCY_NGN = 'currency-ngn',
	CURRENCY_RUB = 'currency-rub',
	CURSOR_TEXT = 'cursor-text',
	CURSOR = 'cursor',
	CYLINDER = 'cylinder',
	DATABASE = 'database',
	DESKTOP_TOWER = 'desktop-tower',
	DESKTOP = 'desktop',
	DETECTIVE = 'detective',
	DEVICE_MOBILE_CAMERA = 'device-mobile-camera',
	DEVICE_MOBILE_SPEAKER = 'device-mobile-speaker',
	DEVICE_MOBILE = 'device-mobile',
	DEVICE_TABLET_CAMERA = 'device-tablet-camera',
	DEVICE_TABLET_SPEAKER = 'device-tablet-speaker',
	DEVICE_TABLET = 'device-tablet',
	DIAMOND = 'diamond',
	DIAMOND_FOUR = 'diamond-four',
	DICE_SIX = 'dice-six',
	DICE_FIVE = 'dice-five',
	DICE_FOUR = 'dice-four',
	DICE_THREE = 'dice-three',
	DICE_TWO = 'dice-two',
	DICE_ONE = 'dice-one',
	DISC = 'disc',
	DISCORD_LOGO = 'discord-logo',
	DIVIDE = 'divide',
	DOG = 'dog',
	DOOR = 'door',
	DOTS_NINE = 'dots-nine',
	DOTS_SIX_VERTICAL = 'dots-six-vertical',
	DOTS_SIX = 'dots-six',
	DOTS_THREE_CIRCLE_VERTICAL = 'dots-three-circle-vertical',
	DOTS_THREE_CIRCLE = 'dots-three-circle',
	DOTS_THREE_OUTLINE_VERTICAL = 'dots-three-outline-vertical',
	DOTS_THREE_OUTLINE = 'dots-three-outline',
	DOTS_THREE_VERTICAL = 'dots-three-vertical',
	DOTS_THREE = 'dots-three',
	DOWNLOAD_SIMPLE = 'download-simple',
	DOWNLOAD = 'download',
	DRIBBBLE_LOGO = 'dribbble-logo',
	DROP_HALF_BOTTOM = 'drop-half-bottom',
	DROP_HALF = 'drop-half',
	DROP = 'drop',
	EAR_SLASH = 'ear-slash',
	EAR = 'ear',
	EGG_CRACK = 'egg-crack',
	EGG = 'egg',
	EJECT_SIMPLE = 'eject-simple',
	EJECT = 'eject',
	ENVELOPE_OPEN = 'envelope-open',
	ENVELOPE_SIMPLE_OPEN = 'envelope-simple-open',
	ENVELOPE_SIMPLE = 'envelope-simple',
	ENVELOPE = 'envelope',
	EQUALIZER = 'equalizer',
	EQUALS = 'equals',
	ERASER = 'eraser',
	EXAM = 'exam',
	EXPORT = 'export',
	EYE_CLOSED = 'eye-closed',
	EYE_SLASH = 'eye-slash',
	EYE = 'eye',
	EYEDROPPER_SAMPLE = 'eyedropper-sample',
	EYEDROPPER = 'eyedropper',
	EYEGLASSES = 'eyeglasses',
	FACE_MASK = 'face-mask',
	FACEBOOK_LOGO = 'facebook-logo',
	FACTORY = 'factory',
	FADERS_HORIZONTAL = 'faders-horizontal',
	FADERS = 'faders',
	FAST_FORWARD_CIRCLE = 'fast-forward-circle',
	FAST_FORWARD = 'fast-forward',
	FIGMA_LOGO = 'figma-logo',
	FILE_ARROW_DOWN = 'file-arrow-down',
	FILE_ARROW_UP = 'file-arrow-up',
	FILE_AUDIO = 'file-audio',
	FILE_CLOUD = 'file-cloud',
	FILE_CODE = 'file-code',
	FILE_CSS = 'file-css',
	FILE_CSV = 'file-csv',
	FILE_DOC = 'file-doc',
	FILE_DOTTED = 'file-dotted',
	FILE_HTML = 'file-html',
	FILE_IMAGE = 'file-image',
	FILE_JPG = 'file-jpg',
	FILE_JSX = 'file-jsx',
	FILE_LOCK = 'file-lock',
	FILE_MINUS = 'file-minus',
	FILE_PDF = 'file-pdf',
	FILE_PLUS = 'file-plus',
	FILE_PNG = 'file-png',
	FILE_PPT = 'file-ppt',
	FILE_RS = 'file-rs',
	FILE_SEARCH = 'file-search',
	FILE_TEXT = 'file-text',
	FILE_TS = 'file-ts',
	FILE_TSX = 'file-tsx',
	FILE_VIDEO = 'file-video',
	FILE_VUE = 'file-vue',
	FILE_X = 'file-x',
	FILE_XLS = 'file-xls',
	FILE_ZIP = 'file-zip',
	FILE = 'file',
	FILES = 'files',
	FILM_SCRIPT = 'film-script',
	FILM_SLATE = 'film-slate',
	FILM_STRIP = 'film-strip',
	FINGERPRINT_SIMPLE = 'fingerprint-simple',
	FINGERPRINT = 'fingerprint',
	FINN_THE_HUMAN = 'finn-the-human',
	FIRE_SIMPLE = 'fire-simple',
	FIRE = 'fire',
	FIRST_AID_KIT = 'first-aid-kit',
	FIRST_AID = 'first-aid',
	FISH_SIMPLE = 'fish-simple',
	FISH = 'fish',
	FLAG_BANNER = 'flag-banner',
	FLAG_CHECKERED = 'flag-checkered',
	FLAG = 'flag',
	FLAME = 'flame',
	FLASHLIGHT = 'flashlight',
	FLASK = 'flask',
	FLOPPY_DISK_BACK = 'floppy-disk-back',
	FLOPPY_DISK = 'floppy-disk',
	FLOW_ARROW = 'flow-arrow',
	FLOWER_LOTUS = 'flower-lotus',
	FLOWER = 'flower',
	FLYING_SAUCER = 'flying-saucer',
	FOLDER_DOTTED = 'folder-dotted',
	FOLDER_LOCK = 'folder-lock',
	FOLDER_MINUS = 'folder-minus',
	FOLDER_NOTCH_MINUS = 'folder-notch-minus',
	FOLDER_NOTCH_OPEN = 'folder-notch-open',
	FOLDER_NOTCH_PLUS = 'folder-notch-plus',
	FOLDER_NOTCH = 'folder-notch',
	FOLDER_OPEN = 'folder-open',
	FOLDER_PLUS = 'folder-plus',
	FOLDER_SIMPLE_DOTTED = 'folder-simple-dotted',
	FOLDER_SIMPLE_LOCK = 'folder-simple-lock',
	FOLDER_SIMPLE_MINUS = 'folder-simple-minus',
	FOLDER_SIMPLE_PLUS = 'folder-simple-plus',
	FOLDER_SIMPLE_STAR = 'folder-simple-star',
	FOLDER_SIMPLE_USER = 'folder-simple-user',
	FOLDER_SIMPLE = 'folder-simple',
	FOLDER_STAR = 'folder-star',
	FOLDER_USER = 'folder-user',
	FOLDER = 'folder',
	FOLDERS = 'folders',
	FOOTBALL = 'football',
	FORK_KNIFE = 'fork-knife',
	FRAME_CORNERS = 'frame-corners',
	FRAME_LOGO = 'frame-logo',
	FUNCTION = 'function',
	FUNNEL_SIMPLE = 'funnel-simple',
	FUNNEL = 'funnel',
	GAME_CONTROLLER = 'game-controller',
	GAS_UP = 'gas-up',
	GAUGE = 'gauge',
	GEAR_SIX = 'gear-six',
	GEAR = 'gear',
	GENDER_FEMALE = 'gender-female',
	GENDER_INTERSEX = 'gender-intersex',
	GENDER_MALE = 'gender-male',
	GENDER_NEUTER = 'gender-neuter',
	GENDER_NONBINARY = 'gender-nonbinary',
	GENDER_TRANSGENDER = 'gender-transgender',
	GHOST = 'ghost',
	GIF = 'gif',
	GIFT = 'gift',
	GIT_BRANCH = 'git-branch',
	GIT_COMMIT = 'git-commit',
	GIT_DIFF = 'git-diff',
	GIT_FORK = 'git-fork',
	GIT_MERGE = 'git-merge',
	GIT_PULL_REQUEST = 'git-pull-request',
	GITHUB_LOGO = 'github-logo',
	GITLAB_LOGO_SIMPLE = 'gitlab-logo-simple',
	GITLAB_LOGO = 'gitlab-logo',
	GLOBE_HEMISPHERE_EAST = 'globe-hemisphere-east',
	GLOBE_HEMISPHERE_WEST = 'globe-hemisphere-west',
	GLOBE_SIMPLE = 'globe-simple',
	GLOBE_STAND = 'globe-stand',
	GLOBE = 'globe',
	GOOGLE_CHROME_LOGO = 'google-chrome-logo',
	GOOGLE_LOGO = 'google-logo',
	GOOGLE_PHOTOS_LOGO = 'google-photos-logo',
	GOOGLE_PLAY_LOGO = 'google-play-logo',
	GOOGLE_PODCASTS_LOGO = 'google-podcasts-logo',
	GRADIENT = 'gradient',
	GRADUATION_CAP = 'graduation-cap',
	GRAPH = 'graph',
	GRID_FOUR = 'grid-four',
	HAMBURGER = 'hamburger',
	HAND_EYE = 'hand-eye',
	HAND_FIRST = 'hand-first',
	HAND_GRABBING = 'hand-grabbing',
	HAND_PALM = 'hand-palm',
	HAND_POINTING = 'hand-pointing',
	HAND_SOAP = 'hand-soap',
	HAND_WAVING = 'hand-waving',
	HAND = 'hand',
	HANDBAG_SIMPLE = 'handbag-simple',
	HANDBAG = 'handbag',
	HANDS_CLAPPING = 'hands-clapping',
	HANDSHAKE = 'handshake',
	HARD_DRIVE = 'hard-drive',
	HARD_DRIVES = 'hard-drives',
	HASH_STRAIGHT = 'hash-straight',
	HASH = 'hash',
	HEADLIGHTS = 'headlights',
	HEADPHONES = 'headphones',
	HEADSET = 'headset',
	HEART_BREAK = 'heart-break',
	HEART_STRAIGHT_BREAK = 'heart-straight-break',
	HEART_STRAIGHT = 'heart-straight',
	HEART = 'heart',
	HEARTBEAT = 'heartbeat',
	HEXAGON = 'hexagon',
	HIGHLIGHTER_CRICLE = 'highlighter-circle',
	HORSE = 'horse',
	HOURGLASS_HIGH = 'hourglass-high',
	HOURGLASS_LOW = 'hourglass-low',
	HOURGLASS_MEDIUM = 'hourglass-medium',
	HOURGLASS_SIMPLE_HIGH = 'hourglass-simple-high',
	HOURGLASS_SIMPLE_LOW = 'hourglass-simple-low',
	HOURGLASS_SIMPLE_MEDIUM = 'hourglass-simple-medium',
	HOURGLASS_SIMPLE = 'hourglass-simple',
	HOURGLASS = 'hourglass',
	HOUSE_LINE = 'house-line',
	HOUSE_SIMPLE = 'house-simple',
	HOUSE = 'house',
	IDENTIFICATION_BADGE = 'indentification-badge',
	IDENTIFICATION_CARD = 'indentification-card',
	IMAGE_SQUARE = 'image-square',
	IMAGE = 'image',
	INFINITY = 'infinity',
	INFO = 'info',
	INSTAGRAM_LOGO = 'instagram-logo',
	INTERSECT = 'intersect',
	JEEP = 'jepp',
	KANBAN = 'kanban',
	KEY_RETURN = 'key-return',
	KEY = 'key',
	KEYBOARD = 'keyboard',
	KEYHOLE = 'keyhole',
	KNIFE = 'knife',
	LADDER_SIMPLE = 'ladder-simple',
	LADDER = 'ladder',
	LAMP = 'lamp',
	LAPTOP = 'laptop',
	LAYOUT = 'layout',
	LEAF = 'leaf',
	LIFEBUOY = 'lifebuoy',
	LIGHTBULB_FILAMENT = 'lightbulb-filament',
	LIGHTBULB = 'lightbulb',
	LIGHTNING_SLASH = 'lightning-slash',
	LIGHTNING = 'lightning',
	LINE_SEGMENT = 'line-segment',
	LINE_SEGMENTS = 'line-segments',
	LINK_BREAK = 'link-break',
	LINK_SIMPLE_BREAK = 'link-simple-break',
	LINK_SIMPLE_HORIZONTAL_BREAK = 'link-simple-horizontal-break',
	LINK_SIMPLE_HORIZONTAL = 'link-simple-horizontal',
	LINK_SIMPLE = 'link-simple',
	LINK = 'link',
	LINKEDIN_LOGO = 'linkedin-logo',
	LINUX_LOGO = 'linux-logo',
	LIST_BULLETS = 'list-bullets',
	LIST_CHECKS = 'list-checks',
	LIST_DASHES = 'list-dashes',
	LIST_NUMBERS = 'list-numbers',
	LIST_PLUS = 'list-plus',
	LIST = 'list',
	LOCK_KEY_OPEN = 'lock-key-open',
	LOCK_KEY = 'lock-key',
	LOCK_LAMINATED_OPEN = 'lock-laminated-open',
	LOCK_LAMINATED = 'lock-laminated',
	LOCK_OPEN = 'lock-open',
	LOCK_SIMPLE_OPEN = 'lock-simple-open',
	LOCK_SIMPLE = 'lock-simple',
	LOCK = 'lock',
	MAGIC_WAND = 'magic-wand',
	MAGNET_STRAIGHT = 'magnet-straight',
	MAGNET = 'magnet',
	MAGNIFYING_GLASS_MINUS = 'magnifying-glass-minus',
	MAGNIFYING_GLASS_PLUS = 'magnifying-glass-plus',
	MAGNIFYING_GLASS = 'magnifying-glass',
	MAP_PIN_LINE = 'map-pin-line',
	MAP_PIN = 'map-pin',
	MAP_TRIFOLD = 'map-trifold',
	MARKER_CIRCLE = 'marker-circle',
	MARTINI = 'martini',
	MASK_HAPPY = 'mask-happy',
	MASK_SAD = 'mask-sad',
	MATH_OPERATIONS = 'math-operations',
	MEDAL = 'medal',
	MEDIUM_LOGO = 'medium-logo',
	MEGAPHONE_SIMPLE = 'megaphone-simple',
	MEGAPHONE = 'megaphone',
	MESSENGER_LOGO = 'messenger-logo',
	MICROPHONE_SLASH = 'microphone-slash',
	MICROPHONE_STAGE = 'microphone-stage',
	MICROPHONE = 'microphone',
	MICROSOFT_EXCEL_LOGO = 'microsoft-excel-logo',
	MICROSOFT_POWERPOINT_LOGO = 'microsoft-powerpoint-logo',
	MICROSOFT_TEAMS_LOGO = 'microsoft-teams-logo',
	MICROSOFT_WORD_LOGO = 'microsoft-word-logo',
	MINUS_CIRCLE = 'minus-circle',
	MINUS = 'minus',
	MONEY = 'money',
	MONITOR_PLAY = 'monitor-play',
	MONITOR = 'monitor',
	MOON_STARS = 'moon-stars',
	MOON = 'moon',
	MOUNTAINS = 'mountains',
	MOUSE_SIMPLE = 'mouse-simple',
	MOUSE = 'mouse',
	MUSIC_NOTE_SIMPLE = 'music-note-simple',
	MUSIC_NOTE = 'music-note',
	MUSIC_NOTES_PLUS = 'music-notes-plus',
	MUSIC_NOTES_SIMPLE = 'music-notes-simple',
	MUSIC_NOTES = 'music-notes',
	NAVIGATION_ARROW = 'navigation-arrow',
	NEEDLE = 'needle',
	NEWSPAPER_CLIPPING = 'newspaper-clipping',
	NEWSPAPER = 'newspaper',
	NOTE_BLANK = 'note-blank',
	NOTE_PENCIL = 'note-pencil',
	NOTE = 'note',
	NOTEBOOK = 'notebook',
	NOTEPAD = 'notepad',
	NOTIFICATION = 'notification',
	NUMBER_CIRCLE_EIGHT = 'number-circle-eight',
	NUMBER_CIRCLE_FIVE = 'number-circle-five',
	NUMBER_CIRCLE_FOUR = 'number-circle-four',
	NUMBER_CIRCLE_NINE = 'number-circle-nine',
	NUMBER_CIRCLE_ONE = 'number-circle-one',
	NUMBER_CIRCLE_SEVEN = 'number-circle-seven',
	NUMBER_CIRCLE_SIX = 'number-circle-six',
	NUMBER_CIRCLE_THREE = 'number-circle-three',
	NUMBER_CIRCLE_TWO = 'number-circle-two',
	NUMBER_CIRCLE_ZERO = 'number-circle-zero',
	NUMBER_EIGHT = 'number-eight',
	NUMBER_FIVE = 'number-five',
	NUMBER_FOUR = 'number-four',
	NUMBER_NINE = 'number-nine',
	NUMBER_ONE = 'number-one',
	NUMBER_SEVEN = 'number-seven',
	NUMBER_SIX = 'number-six',
	NUMBER_THREE = 'number-three',
	NUMBER_TWO = 'number-two',
	NUMBER_ZERO = 'number-zero',
	NUMBER_SQUARE_EIGHT = 'number-square-eight',
	NUMBER_SQUARE_FIVE = 'number-square-five',
	NUMBER_SQUARE_FOUR = 'number-square-four',
	NUMBER_SQUARE_NINE = 'number-square-nine',
	NUMBER_SQUARE_ONE = 'number-square-one',
	NUMBER_SQUARE_SEVEN = 'number-square-seven',
	NUMBER_SQUARE_SIX = 'number-square-six',
	NUMBER_SQUARE_THREE = 'number-square-three',
	NUMBER_SQUARE_TWO = 'number-square-two',
	NUMBER_SQUARE_ZERO = 'number-square-zero',
	NUT = 'nut',
	NY_TIMES_LOGO = 'ny-times-logo',
	OCTAGON = 'octagon',
	OPTION = 'option',
	PACKAGE = 'package',
	PAINT_BRUSH_BROAD = 'paint-brush-broad',
	PAINT_BRUSH_HOUSEHOLD = 'paint-brush-household',
	PAINT_BRUSH = 'paint-brush',
	PAINT_BUCKET = 'paint-bucket',
	PAINT_ROLLER = 'paint-roller',
	PALETTE = 'palette',
	PAPER_PLANE_RIGHT = 'paper-plane-right',
	PAPER_PLANE_TILT = 'paper-plane-tilt',
	PAPER_PLANE = 'paper-plane',
	PAPERCLIP_HORIZONTAL = 'paperclip-horizontal',
	PAPERCLIP = 'paperclip',
	PARACHUTE = 'parachute',
	PASSWORD = 'password',
	PATH = 'path',
	PAUSE_CIRCLE = 'pause-circle',
	PAUSE = 'pause',
	PAW_PRINT = 'paw-print',
	PEACE = 'peace',
	PEN_NIB_STRAIGHT = 'pen-nib-straight',
	PEN_NIB = 'pen-nib',
	PEN = 'pen',
	PENCIL_CIRCLE = 'pencil-circle',
	PENCIL_LINE = 'pencil-line',
	PENCIL_SIMPLE_LINE = 'pencil-simple-line',
	PENCIL_SIMPLE = 'pencil-simple',
	PENCIL = 'pencil',
	PERCENT = 'percent',
	PERSON_SIMPLE_RUN = 'person-simple-run',
	PERSON_SIMPLE_WALK = 'person-simple-walk',
	PERSON_SIMPLE = 'person-simple',
	PERSON = 'person',
	PERSPECTIVE = 'perspective',
	PHONE_CALL = 'phone-call',
	PHONE_DISCONNECT = 'phone-diconnect',
	PHONE_INCOMING = 'phone-incoming',
	PHONE_OUTGOING = 'phone-outgoing',
	PHONE_SLASH = 'phone-slash',
	PHONE_X = 'phone-x',
	PHONE = 'phone',
	PHOSPHOR_LOGO = 'phosphor-logo',
	PIANO_KEYS = 'piano-keys',
	PICTURE_IN_PICTURE = 'picture-in-picture',
	PILL = 'pill',
	PINTEREST_LOGO = 'pinterest-logo',
	PINWHEEL = 'pinwheel',
	PIZZA = 'pizza',
	PLACEHOLDER = 'placeholder',
	PLANET = 'planet',
	PLAY_CIRCLE = 'play-circle',
	PLAY = 'play',
	PLAYLIST = 'playlist',
	PLUG = 'plug',
	PLUGS_CONNECTED = 'plugs-connected',
	PLUGS = 'plugs',
	PLUS_CIRCLE = 'plus-circle',
	PLUS_MINUS = 'plus-minus',
	PLUS = 'plus',
	POKER_CHIP = 'poker-chip',
	POLICE_CAR = 'police-car',
	POLYGON = 'ploygon',
	POPCORN = 'popcorn',
	POWER = 'power',
	PRESCRIPTION = 'prescription',
	PRESENTATION_CHART = 'presentation-chart',
	PRESENTATION = 'presentation',
	PRINTER = 'printer',
	PROHIBIT_INSET = 'prohibit-inset',
	PROHIBIT = 'prohibit',
	PROJECTOR_SCREEN_CHART = 'projector-screen-chart',
	PROJECTOR_SCREEN = 'projector-screen',
	PUSH_PIN_SIMPLE_SLASH = 'push-pin-simple-slash',
	PUSH_PIN_SIMPLE = 'push-pin-simple',
	PUSH_PIN_SLASH = 'push-pin-slash',
	PUSH_PIN = 'push-pin',
	PUZZLE_PIECE = 'puzzle-piece',
	QR_CODE = 'qr-code',
	QUESTION = 'question',
	QUEUE = 'queue',
	QUOTES = 'quotes',
	RADICAL = 'radical',
	RADIO_BUTTON = 'radio-button',
	RADIO = 'radio',
	RAINBOW_CLOUD = 'raindbow-cloud',
	RAINBOW = 'raindbow',
	RECEIPT = 'receipt',
	RECORD = 'record',
	RECTANGLE = 'rectangle',
	RECYCLE = 'recycle',
	REDDIT_LOGO = 'reddit-logo',
	REPEAT_ONCE = 'repeat-once',
	REPEAT = 'repeat',
	REWIND_CIRCLE = 'rewind-circle',
	REWIND = 'rewind',
	ROBOT = 'robot',
	ROCKET_LAUNCH = 'rocket-launch',
	ROCKET = 'rocket',
	ROWS = 'rows',
	RSS_SIMPLE = 'rss-simple',
	RSS = 'rss',
	RUG = 'rug',
	RULER = 'ruler',
	SCALES = 'scales',
	SCAN = 'scan',
	SCISSORS = 'scissors',
	SCREENCAST = 'screencast',
	SCRIBBLE_LOOP = 'scribble-loop',
	SCROLL = 'scroll',
	SELECTION_ALL = 'selection-all',
	SELECTION_BACKGROUND = 'selection-background',
	SELECTION_FOREGROUND = 'selection-foreground',
	SELECTION_INVERSE = 'selection-inverse',
	SELECTION_PLUS = 'selection-plus',
	SELECTION_SLASH = 'selection-slash',
	SELECTION = 'selection',
	SHARE_NETWORK = 'share-network',
	SHARE = 'share',
	SHIELD_CHECK = 'shield-check',
	SHIELD_CHECKERED = 'shield-checkered',
	SHIELD_CHEVRON = 'shield-chevron',
	SHIELD_PLUS = 'shield-plus',
	SHIELD_SLASH = 'shield-slash',
	SHIELD_STAR = 'shield-star',
	SHIELD_WARNING = 'shield-warning',
	SHIELD = 'shield',
	SHOPPING_BAG_OPEN = 'shopping-bag-open',
	SHOPPING_BAG = 'shopping-bag',
	SHOPPING_CART_SIMPLE = 'shopping-cart-simple',
	SHOPPING_CART = 'shopping-cart',
	SHOWER = 'shower',
	SHUFFLE_ANGULAR = 'shuffle-angular',
	SHUFFLE_SIMPLE = 'shuffle-simple',
	SHUFFLE = 'shuffle',
	SIEDBAR_SIMPLE = 'sidebar-simple',
	SIEDBAR = 'sidebar',
	SIGN_IN = 'sign-in',
	SIGN_OUT = 'sign-out',
	SIGNPOST = 'signpost',
	SIM_CARD = 'sim-card',
	SKETCH_LOGO = 'sketch-logo',
	SKIP_BACK_CIRCLE = 'skip-back-circle',
	SKIP_BACK = 'skip-back',
	SKIP_FORWARD_CIRCLE = 'skip-forward-circle',
	SKIP_FORWARD = 'skip-forward',
	SKULL = 'skull',
	SLACK_LOGO = 'slack-logo',
	SLIDERS_HORIZONTAL = 'sliders-horizontal',
	SLIDERS = 'sliders',
	SMILEY_BLANK = 'smiley-blank',
	SMILEY_MEH = 'smiley-meh',
	SMILEY_NERVOUS = 'smiley-nervous',
	SMILEY_SAD = 'smiley-sad',
	SMILEY_STICKER = 'smiley-sticker',
	SMILEY_WINK = 'smiley-wink',
	SMILEY_X_EYES = 'smiley-x-eyes',
	SMILEY = 'smiley',
	SNAPCHAT_LOGO = 'snapchat-logo',
	SNOWFLAKE = 'snowflake',
	SOCCER_BALL = 'soccer-ball',
	SORT_ASCENDING = 'sort-ascending',
	SORT_DESCENDING = 'sort-descending',
	SPADE = 'spade',
	SPARKLE = 'sparkle',
	SPEAKER_HIGH = 'speaker-high',
	SPEAKER_LOW = 'speaker-low',
	SPEAKER_NONE = 'speaker-none',
	SPEAKER_SIMPLE_HIGH = 'speaker-simple-high',
	SPEAKER_SIMPLE_LOW = 'speaker-simple-low',
	SPEAKER_SIMPLE_NONE = 'speaker-simple-none',
	SPEAKER_SIMPLE_SLASH = 'speaker-simple-slash',
	SPEAKER_SIMPLE_X = 'speaker-simple-x',
	SPEAKER_SLASH = 'speaker-slash',
	SPEAKER_X = 'speaker-x',
	SPINNER_GAP = 'spinner-gap',
	SPINNER = 'spinner',
	SPIRAL = 'spiral',
	SPOTIFY_LOGO = 'spotify-logo',
	SQUARE_HALF_BOTTOM = 'square-half-bottom',
	SQUARE_HALF = 'square-half',
	SQUARE_LOGO = 'square-logo',
	SQUARE = 'square',
	SQUARES_FOUR = 'squares-four',
	STACK_OVERFLOW_LOGO = 'stack-overflow-logo',
	STACK_SIMPLE = 'stack-simple',
	STACK = 'stack',
	STAMP = 'stamp',
	STAR_FOUR = 'star-four',
	STAR_HALF = 'star-half',
	STAR = 'star',
	STICKER = 'sticker',
	STOP_CIRCLE = 'stop-circle',
	STOP = 'stop',
	STOREFRONT = 'storefront',
	STRATEGY = 'strategy',
	STRIPE_LOGO = 'stripe-logo',
	STUDENT = 'student',
	SUITCASE_SIMPLE = 'suitcase-simple',
	SUITCASE = 'suitcase',
	SUN_DIM = 'sun-dim',
	SUN_HORIZON = 'sun-horizon',
	SUN = 'sun',
	SUNGLASSES = 'sunglasses',
	SWAP = 'swap',
	SWATCHES = 'swatches',
	SWORD = 'sword',
	SYRINGE = 'syringe',
	T_SHIRT = 't-shirt',
	TABLE = 'table',
	TABS = 'tabs',
	TAG_CHEVRON = 'tag-chevron',
	TAG_SIMPLE = 'tag-simple',
	TAG = 'tag',
	TARGET = 'target',
	TAXI = 'taxi',
	TELEGRAM_LOGO = 'telegram-logo',
	TELEVISION_SIMPLE = 'television-simple',
	TELEVISION = 'television',
	TENNIS_BALL = 'tennis-ball',
	TERMINAL_WINDOW = 'terminal-window',
	TERMINAL = 'terminal',
	TEST_TUBE = 'test-tube',
	TEXT_AA = 'text-aa',
	TEXT_ALIGN_CENTER = 'text-align-center',
	TEXT_ALIGN_JUSTIFY = 'text-align-justify',
	TEXT_ALIGN_LEFT = 'text-align-left',
	TEXT_ALIGN_RIGHT = 'text-align-right',
	TEXT_BOLDER = 'text-bolder',
	TEXT_H_FIVE = 'text-h-five',
	TEXT_H_FOUR = 'text-h-four',
	TEXT_H_ONE = 'text-h-one',
	TEXT_H_SIX = 'text-h-six',
	TEXT_H_THREE = 'text-h-three',
	TEXT_H_TWO = 'text-h-two',
	TEXT_H = 'text-h',
	TEXT_INDENT = 'text-indent',
	TEXT_ITALIC = 'text-italic',
	TEXT_OUTDENT = 'text-outdent',
	TEXT_STRIKETHROUGH = 'text-strikethrough',
	TEXT_T = 'text-t',
	TEXT_UNDERLINE = 'text-underline',
	TEXTBOX = 'textbox',
	THERMOMETER_COLD = 'thermometer-cold',
	THERMOMETER_HOT = 'thermometer-hot',
	THERMOMETER_SIMPLE = 'thermometer-simple',
	THERMOMETER = 'thermometer',
	THUMBS_DOWN = 'thumbs-down',
	THUMBS_UP = 'thumbs-up',
	TICKET = 'ticket',
	TIKTOK_LOGO = 'tiktok-logo',
	TIMER = 'timer',
	TOGGLE_LEFT = 'toggle-left',
	TOGGLE_RIGHT = 'toggle-right',
	TOILET_PAPER = 'toilet-paper',
	TOILET = 'toilet',
	TOTE_SIMPLE = 'tote-simple',
	TOTE = 'tote',
	TRADEMARK_REGISTERED = 'trademark-registered',
	TRAFFIC_CONE = 'traffic-cone',
	TRAFFIC_SIGN = 'traffic-sign',
	TRAFFIC_SIGNAL = 'traffic-signal',
	TRAIN_REGIONAL = 'train-regional',
	TRAIN_SIMPLE = 'train-simple',
	TRAIN = 'train',
	TRANSLATE = 'translate',
	TRASH_SIMPLE = 'trash-simple',
	TRASH = 'trash',
	TRAY = 'tray',
	TREE_EVERGREEN = 'tree-evergreen',
	TREE_STRUCTURE = 'tree-structure',
	TREE = 'tree',
	TREND_DOWN = 'trend-down',
	TREND_UP = 'trend-up',
	TRIANGLE = 'triangle',
	TROPHY = 'trophy',
	TRUCK = 'truck',
	TWITCH_LOGO = 'twitch-logo',
	TWITTER_LOGO = 'twitter-logo',
	UMBRELLA_SIMPLE = 'umbrella-simple',
	UMBRELLA = 'umbrella',
	UPLOAD_SIMPLE = 'upload-simple',
	UPLOAD = 'upload',
	USER_CIRCLE_GEAR = 'user-circle-gear',
	USER_CIRCLE_MINUS = 'user-circle-minus',
	USER_CIRCLE_PLUS = 'user-circle-plus',
	USER_CIRCLE = 'user-circle',
	USER_FOCUS = 'user-focus',
	USER_GEAR = 'user-gear',
	USER_LIST = 'user-list',
	USER_MINUS = 'user-minus',
	USER_PLUS = 'user-plus',
	USER_RECTANGLE = 'user-rectangle',
	USER_SQUARE = 'user-square',
	USER_SWITCH = 'user-switch',
	USER = 'user',
	USERS_FOUR = 'users-four',
	USERS_THREE = 'users-three',
	USERS = 'users',
	VAULT = 'vault',
	VIBRATE = 'vibrate',
	VIDEO_CAMERA_SLASH = 'video-camera-slash',
	VIDEO_CAMERA = 'video-camera',
	VIGNETTE = 'vignette',
	VOICEMAIL = 'voicemail',
	VOLLEYBALL = 'volleyball',
	WALL = 'wall',
	WALLET = 'wallet',
	WARNING_CIRCLE = 'warning-circle',
	WARNING_OCTAGON = 'warning-octagon',
	WARNING = 'warning',
	WATCH = 'watch',
	WAVE_SAWTOOTH = 'wave-sawtooth',
	WAVE_SINE = 'wave-sine',
	WAVE_SQUARE = 'wave-square',
	WAVE_TRIANGLE = 'wave-triangle',
	WAVES = 'waves',
	WEBCAM = 'webcam',
	WHATSAPP_LOGO = 'whatsapp-logo',
	WHEELCHAIR = 'wheelchair',
	WIFI_HIGH = 'wifi-high',
	WIFI_LOW = 'wifi-low',
	WIFI_MEDIUM = 'wifi-medium',
	WIFI_NONE = 'wifi-none',
	WIFI_SLASH = 'wifi-slash',
	WIFI_X = 'wifi-x',
	WIND = 'wind',
	WINDOWS_LOGO = 'windows-logo',
	WINE = 'wine',
	WRENCH = 'wrench',
	X_CIRCLE = 'x-circle',
	X_SQUARE = 'x-square',
	X = 'x',
	YING_YANG = 'ying-yang',
	YOUTUBE_LOGO = 'youtube-logo'
}
