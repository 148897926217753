let trapElement: HTMLElement;

const handleFocusChange = (event: FocusEvent) => {
	const focusedElement = event.relatedTarget;
	if (focusedElement === null) {
		return;
	}
	if (trapElement && !trapElement.contains(focusedElement as HTMLElement)) {
		const focusableElements = getKeyboardFocusableElements(trapElement);
		if (focusableElements.length > 0) {
			const firstFocusableElement = focusableElements[0];
			firstFocusableElement.focus();
		}
	}
};

const getKeyboardFocusableElements = (searchElement: HTMLElement = document.documentElement): Array<HTMLElement> => {
	return [
		...Array.from(searchElement?.querySelectorAll('a[href], button, input, textarea, select, details, embed, object, iframe, [tabindex]:not([tabindex="-1"])'))
	].filter(
		(element) => {
			if (element.hasAttribute('disabled') || element.getAttribute('aria-hidden')) {
				return false;
			}
			const computedStyle = window.getComputedStyle(element);
			// noinspection RedundantIfStatementJS
			if (computedStyle?.display === 'none') {
				return false;
			}
			return true;
		}
	) as Array<HTMLElement>;
};

export const registerFocusTrap = (modalElement: HTMLElement): void => {
	trapElement = modalElement;
	window.addEventListener('focusout', handleFocusChange);
};

export const deregisterFocusTrap = (): void => {
	window.removeEventListener('focusout', handleFocusChange);
};
