import { ButtonHTMLAttributes, ReactNode } from 'react';

import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import './ButtonIcon.scss';

export type ButtonIconProps = {
	children: ReactNode,
	customKey?: number,
	disabled?: boolean
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'key' | 'disabled'>;

export function ButtonIcon(props: ButtonIconProps) {
	const { type = 'button', children, customKey, disabled = false, ...rest } = props;

	const classMap = {
		'button-icon': true,
		'button-icon--disabled': disabled
	};

	return (
		<button
			type={type}
			className={buildCssClassStringFromClassMap(classMap)}
			key={customKey}
			disabled={disabled}
			{...rest}
		>
			{children}
		</button>
	);
}
