import React, { ReactNode } from 'react';

import './NavigationFooter.scss';

export type NavigationFooterProps = {
	children: ReactNode
};

export function NavigationFooter(props: NavigationFooterProps) {

	const { children } = props;

	return (
		<footer className="navigation-footer">
			{children}
		</footer>
	);
}
