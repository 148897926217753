import React, { ReactNode } from 'react';

import './AppLayoutMain.scss';

export type AppLayoutMainProps = {
	children: ReactNode
};

export function AppLayoutMain(props: AppLayoutMainProps) {

	const { children } = props;

	return (
		<main className="app-layout-main">
			{children}
			<footer className="app-layout-main__footer">
				<img src="/assets/lib-oms-ui-primitive/images/abb-logo-grey.svg" width="62" height="24" alt="ABB" />
			</footer>
		</main>
	);
}
