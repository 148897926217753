import React, { ReactNode } from 'react';

import './AppLayoutMainContent.scss';

export type AppLayoutMainContentProps = {
	children: ReactNode
};

export function AppLayoutMainContent(props: AppLayoutMainContentProps) {

	const { children } = props;

	return (
		<main className="app-layout-main-content">
			{children}
		</main>
	);
}
