import { createContext, useContext } from 'react';

import { ToastEntry, ToastEntryId } from './ToastManager.types';

export type ToastManagerContextValue = {
	setToastEntries: (entries: Array<ToastEntry>) => Array<ToastEntryId>,
	addToastEntry: (entry: ToastEntry) => ToastEntryId,
	removeToastEntry: (entryId: ToastEntryId) => void,
	getToastEntries: () => Array<ToastEntry>
};

export const toastManagerContext = createContext<ToastManagerContextValue>({
	setToastEntries: (_entries: Array<ToastEntry>): Array<ToastEntryId> => {
		throw new Error('No toast collection provided');
	},
	addToastEntry: (_entry: ToastEntry): ToastEntryId => {
		throw new Error('No toast collection provided');
	},
	removeToastEntry: (_entryId: ToastEntryId): void => {
		throw new Error('No toast collection provided');
	},
	getToastEntries: (): Array<ToastEntry> => {
		throw new Error('No toast collection provided');
	}
});

export const useToastManager = () => {
	return useContext(toastManagerContext);
};
