import React, { ReactElement, ReactNode } from 'react';

import { useAuth } from '@abb-emobility/shared/auth-provider';
import { CreateError, DeleteError, FetchError, MutateError, TaskAssignError, TaskCompleteError } from '@abb-emobility/shared/business-error';
import {
	AccessDeniedError,
	AuthenticationFailedError,
	AuthenticationRequiredError,
	ErrorHandler,
	NotFoundError,
	SimpleError,
	WebSocketError
} from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { useForceRerender } from '@abb-emobility/shared/react';
import { ButtonSecondary, NotificationBig, NotificationBigStatus } from '@abb-emobility/shared/ui-primitive';
import { Maybe, Nullable } from '@abb-emobility/shared/util';

export type UiErrorHandlerProps = {
	children: ReactNode
};

export function UiErrorHandler(props: UiErrorHandlerProps) {

	const { children } = props;

	const auth = useAuth();
	const l10n = useL10n();
	const rerender = useForceRerender();

	const renderError = (error: Error): Nullable<ReactElement> => {

		if (error instanceof WebSocketError) {
			return null;
		}

		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			auth.reauthenticate();
		}

		const nonRecoverableError = error instanceof AccessDeniedError || error instanceof NotFoundError;

		let heading: string;
		let message: string;
		let subline: Maybe<string>;
		let recoverButton: ReactNode;

		if (error instanceof SimpleError) {
			recoverButton = nonRecoverableError ? null : (
				<ButtonSecondary
					label={l10n.translate('sharedUiErrorHandler.error.recoverLabel')}
					onClick={rerender.invoke}
				/>
			);

			if (error instanceof FetchError) {
				heading = l10n.translate('sharedUiErrorHandler.error.fetchHeading');
			} else if (error instanceof MutateError) {
				heading = l10n.translate('sharedUiErrorHandler.error.mutateHeading');
			} else if (error instanceof CreateError) {
				heading = l10n.translate('sharedUiErrorHandler.error.createHeading');
			} else if (error instanceof DeleteError) {
				heading = l10n.translate('sharedUiErrorHandler.error.deleteHeading');
			} else if (error instanceof TaskAssignError) {
				heading = l10n.translate('sharedUiErrorHandler.error.taskAssignHeading');
			} else if (error instanceof TaskCompleteError) {
				heading = l10n.translate('sharedUiErrorHandler.error.taskCompleteHeading');
			} else {
				heading = l10n.translate('sharedUiErrorHandler.error.generic.heading');
			}

			message = error.message;
			subline = error.previousError?.message;
		} else {
			recoverButton = (
				<ButtonSecondary
					label={l10n.translate('sharedUiErrorHandler.error.generic.resolveAction.label')}
					onClick={() => window.location.reload()}
				/>
			);
			heading = l10n.translate('sharedUiErrorHandler.error.generic.heading');
			message = l10n.translate('sharedUiErrorHandler.error.generic.message');
			subline = error.message;
		}

		return (
			<NotificationBig
				status={NotificationBigStatus.ERROR}
				heading={heading}
				message={message}
				subline={subline}
			>
				{recoverButton}
			</NotificationBig>
		);
	};

	return (
		<ErrorHandler errorComponent={renderError} key={rerender.key()}>
			{children}
		</ErrorHandler>
	);
}
