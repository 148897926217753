import { buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import { SpinnerDotsTheme } from './SpinnerDots.enums';

import './SpinnerDots.scss';

export type SpinnerDotsProps = {
	theme?: SpinnerDotsTheme
};

export function SpinnerDots(props: SpinnerDotsProps) {

	const { theme = SpinnerDotsTheme.DARK } = props;

	return (
		<div className={`spinner-dots ${buildCssClassNameFromEnumValue(theme, 'spinner-dots')}`}>
			<div className="spinner-dots__animation">
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
}
