import { useState } from 'react';
import {v4 as uuid} from 'uuid';

export const useForceRerender = () => {

	const [rerender, setRerender] = useState<string>(uuid());

	return {
		invoke: (): void => {
			setRerender(uuid());
		},
		key: (): string => {
			return rerender;
		}
	};

};
