import { useMemo } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

type SearchParams = {
	get: (name: string) => Nullable<string>,
	all: () => Map<string, string>
};

export const useSearchParams = (): SearchParams => {
	const search = window.location.search;
	const urlQueryParams = useMemo(() => {
		return new URLSearchParams(search);
	}, [search]);
	return {
		get: (name: string): Nullable<string> => {
			return urlQueryParams.get(name) ?? null;
		},
		all: () => {
			const all = new Map();
			urlQueryParams.forEach((value, key) => {
				all.set(key, value);
			});
			return all;
		}
	};
};
