import React, { ReactNode } from 'react';

import './Navigation.scss';

export type NavigationProps = {
	children: ReactNode
};

export function Navigation(props: NavigationProps) {

	const { children } = props;

	return (
		<article className="navigation">
			<header className="navigation__logo">
				<img src="/assets/lib-oms-ui-primitive/images/abb-logo-red.svg" width="85" height="32" alt="ABB" />
			</header>
			{children}
		</article>
	);

}
