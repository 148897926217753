import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { scrollWindowToTop } from '@abb-emobility/shared/browser';

export type ScrollToTopProps = {
	behavior?: ScrollBehavior
};

export function ScrollToTop(props: ScrollToTopProps) {

	const { behavior } = props;

	const location = useLocation();

	useMemo(() => {
		scrollWindowToTop(behavior);
	}, [location.pathname]);

	return null;
}
