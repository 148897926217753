import { buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import { IconIdentifier, IconSize } from './Icon.enums';

import './IconSvg.scss';

type IconProps = {
	name: IconIdentifier,
	size?: IconSize
};

export function IconSvg(props: IconProps) {

	const { name, size } = props;
	// const { name, size = IconSize.MEDIUM } = props;

	return (
		<div className={`icon ${size ? buildCssClassNameFromEnumValue(size, 'icon') : ''}`}>
			<svg>
				<use href={`/assets/lib-oms-ui-primitive/icons/icon.min.svg#${name}`} />
			</svg>
		</div>
	);

}

export default IconSvg;
