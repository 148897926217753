import React, { ReactNode } from 'react';

import './NavigationItems.scss';

export type NavigationItemsProps = {
	children?: ReactNode
};

export function NavigationItems(props: NavigationItemsProps) {

	const { children } = props;

	return (
		<nav className="navigation-items">
			{children}
		</nav>
	);
}
