import { ChangeEvent, ReactNode, SelectHTMLAttributes } from 'react';

import { buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import { LabelPosition } from '../../types/LabelPosition';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './InputSelect.scss';

export type InputSelectOption<T extends string = string> = {
	label: string,
	value: T
};

export type InputSelectProps<T extends string = string> = {
	label?: string,
	labelPosition?: LabelPosition,
	placeholder?: string,
	options?: Array<InputSelectOption<T>>,
	onSelect?: (value: T) => void
} & Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onSelect'>;

export function InputSelect<T extends string = string>(props: InputSelectProps<T>) {
	const { label, labelPosition = LabelPosition.TOP, placeholder, options = [], value = '', onSelect, ...rest } = props;

	const handleSelect = (event: ChangeEvent<HTMLSelectElement>): void => {
		if (onSelect !== undefined) {
			onSelect(event.currentTarget.value as T);
		}
	};

	const renderLabel = () => {
		if (label === undefined) {
			return null;
		}
		return (
			<span className={`input-select__label ${buildCssClassNameFromEnumValue(labelPosition, 'input-select__label')}`}>
				{label}
			</span>
		);
	};

	const renderOptions = () => {
		const optionElements: Array<ReactNode> = [];
		if (placeholder !== undefined) {
			optionElements.push(
				<option
					value=""
					disabled={true}
					key={'placeholder'}
				>
					{placeholder}
				</option>
			);
		}

		for (const option of options) {
			optionElements.push(
				<option value={option.value} key={option.value}>{option.label}</option>
			);
		}

		return optionElements;
	};

	return (
		<label className={`input-select ${buildCssClassNameFromEnumValue(labelPosition, 'input-select')}`}>
			{renderLabel()}
			<span className="input-select__field">
				<select
					className={`input-select__field__select ${buildCssClassNameFromEnumValue(labelPosition, 'input-select__field__select')}`}
					name="default"
					id="default-select"
					defaultValue={value}
					onChange={handleSelect}
					{...rest}
				>
					{renderOptions()}
				</select>
				<span className="input-select__field__icon">
					<Icon name={IconIdentifier.CARET_DOWN} />
				</span>
			</span>
		</label>
	);
}
