import React, { ReactNode } from 'react';

import './CollectionItemContentSection.scss';

export type CollectionItemContentSectionProps = {
	children?: ReactNode,
	heading?: string
};

export function CollectionItemContentSection(props: CollectionItemContentSectionProps): React.JSX.Element {
	const { children, heading } = props;

	const renderHeading = () => {
		if (heading) {
			return <header className="collection-item-content-section__heading">{heading}</header>;
		}

		return;
	};

	const renderChildren = () => {
		if (children) {
			return (
				<main className="collection-item-content-section__items">
					{children}
				</main>
			);
		}

		return;
	};

	return (
		<section className="collection-item-content-section">
			{renderHeading()}
			{renderChildren()}
		</section>
	);
}
