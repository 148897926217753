import { createContext, useContext } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

import { ModalDialogueSpec } from '../modal-dialogue/ModalDialogue.types';

export type ModalDialogueManagerContextValue = {
	push: (dialogue: ModalDialogueSpec) => void,
	replace: (dialogue: ModalDialogueSpec) => Nullable<ModalDialogueSpec>,
	pop: () => Nullable<ModalDialogueSpec>,
	size: () => number,
	clear: () => void
};

export const modalDialogueManagerContext = createContext<ModalDialogueManagerContextValue>({
	push: (_dialogue: ModalDialogueSpec): void => {
		throw new Error('No dialogue manager provided');
	},
	replace: (_dialogue: ModalDialogueSpec): Nullable<ModalDialogueSpec> => {
		throw new Error('No dialogue manager provided');
	},
	pop: (): Nullable<ModalDialogueSpec> => {
		throw new Error('No dialogue manager provided');
	},
	size: (): number => {
		throw new Error('No dialogue manager provided');
	},
	clear: (): void => {
		throw new Error('No dialogue manager provided');
	}
});

export const useModalDialogueManager = () => {
	return useContext(modalDialogueManagerContext);
};
