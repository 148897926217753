import './ViewHeader.scss';

export type ViewHeaderProps = {
	heading: string
};

export function ViewHeader(props: ViewHeaderProps) {
	const { heading } = props;

	return (
		<header className="view-header">
			<h1 className="view-header__heading">{heading}</h1>
		</header>
	);
}
