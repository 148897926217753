import { ChangeEvent, InputHTMLAttributes } from 'react';

import './InputCheckbox.scss';

export type InputCheckboxProps<T> = {
	label: string,
	heading?: string,
	value?: T,
	onChange?: (checked: boolean, value?: T) => void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>;

export function InputCheckbox<T extends string | number>(props: InputCheckboxProps<T>) {
	const { label, heading = null, onChange, ...rest } = props;

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		if (onChange !== undefined) {
			// onChange(event.target.checked);
			onChange(event.target.checked, event.target.value as T);
		}
	};

	const renderHeading = () => {
		if (heading === null) {
			return;
		}
		return (
			<span className="input-checkbox__content__heading">{heading}</span>
		);
	};

	return (
		<label className="input-checkbox">
			<span className="input-checkbox__input">
				<input type="checkbox" onChange={handleChange} {...rest} />
			</span>
			<span className="input-checkbox__content">
				{renderHeading()}
				<span className="input-checkbox__content__label">{label}</span>
			</span>
		</label>
	);
}
