import { ReactNode } from 'react';

import './ButtonGroup.scss';

export type ButtonGroupProps = {
	children: ReactNode
};

export function ButtonGroup(props: ButtonGroupProps) {
	const { children } = props;

	return (
		<section className="button-group">
			{children}
		</section>
	);
}
