import React, { ReactNode } from 'react';

import './AppLayoutMainHeader.scss';

export type AppLayoutMainHeaderProps = {
	children?: ReactNode
};

export function AppLayoutMainHeader(props: AppLayoutMainHeaderProps) {

	const { children } = props;

	return (
		<header className="app-layout-main__header">
			{children}
		</header>
	);
}
