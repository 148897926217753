import { ReactNode, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { arrayReverse } from '@abb-emobility/shared/util';

import { toastManagerContext } from './ToastManager.context';
import { ToastEntry, ToastEntryId } from './ToastManager.types';
import { Toast } from './toast/Toast';

import './ToastManager.scss';

export type ToastManagerProps = {
	children: ReactNode
};

export function ToastManager(props: ToastManagerProps) {

	const { children } = props;

	const [toastOrder, setToastOrder] = useState<Array<ToastEntryId>>([]);
	const [toasts, setToasts] = useState<Map<ToastEntryId, ToastEntry>>(new Map());

	const providerValue = {
		setToastEntries: (entries: Array<ToastEntry>): Array<ToastEntryId> => {
			const entryMap = new Map();
			const entryOrder = [];
			for (const entry of entries) {
				const entryId = uuid();
				entryMap.set(entryId, entry);
				entryOrder.push(entryId);
			}
			setToasts(entryMap);
			setToastOrder(entryOrder);
			return entryOrder;
		},
		addToastEntry: (entry: ToastEntry): ToastEntryId => {
			const entryMap = new Map(toasts);
			const entryId = uuid();
			entryMap.set(entryId, entry);
			setToasts(entryMap);
			const entryOrder = [...toastOrder];
			entryOrder.push(entryId);
			setToastOrder(entryOrder);
			return entryId;
		},
		removeToastEntry: (entryId: ToastEntryId): void => {
			const entryMap = new Map(toasts);
			entryMap.delete(entryId);
			setToasts(entryMap);
			const entryOrder = [...toastOrder];
			const index = entryOrder.indexOf(entryId);
			if (index > -1) {
				entryOrder.splice(index, 1);
			}
			setToastOrder(entryOrder);
		},
		getToastEntries: (): Array<ToastEntry> => {
			return toastOrder
				.map((entryId) => {
					return toasts.get(entryId);
				})
				.filter((toast): toast is ToastEntry => {
					return toast !== undefined;
				});
		}
	};

	const renderToastCollection = (): ReactNode => {
		if (toasts.size === 0) {
			return null;
		}

		const toastElements: ReactNode = arrayReverse(toastOrder).map((toastId, index) => {
			const toast = toasts.get(toastId);
			if (toast === undefined) {
				return null;
			}
			return (
				<Toast
					toastEntry={toast}
					toastEntryId={toastId}
					stackPosition={index}
					key={toastId}
				/>
			);
		});

		return (
			<article className="toast-collection">
				{toastElements}
			</article>
		);
	};

	return (
		<toastManagerContext.Provider value={providerValue}>
			{children}
			{renderToastCollection()}
		</toastManagerContext.Provider>
	);

}
