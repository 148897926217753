import { ErrorFeedback, ErrorFeedbackAction } from '@abb-emobility/shared/ui-primitive';

export function DefaultErrorView() {

	const reloadAction: ErrorFeedbackAction = {
		label: 'Reload',
		onInvoke: (): void => {
			window.location.reload();
		}
	};

	return (
		<ErrorFeedback
			heading="Something went wrong"
			message="An unrecoverable error happened"
			actions={[reloadAction]}
		/>
	);
}
