import React, { ForwardedRef, ReactNode, useEffect, useRef } from 'react';

import { buildCssClassNameFromEnumValue, Nullable } from '@abb-emobility/shared/util';

import { Icon } from '../../icon/Icon';
import { IconIdentifier, IconSize } from '../../icon/Icon.enums';
import { useToastManager } from '../ToastManager.context';
import { ToastEntry, ToastEntryId, ToastLevel } from '../ToastManager.types';

import './Toast.scss';

export type ToastProps = {
	toastEntry: ToastEntry,
	toastEntryId: ToastEntryId,
	stackPosition: number
};

export const Toast = React.forwardRef((props: ToastProps, ref: ForwardedRef<HTMLElement>) => {

	const { toastEntry, toastEntryId, stackPosition } = props;

	const toast = useToastManager();
	const dismissTimeout = useRef<Nullable<number>>(null);

	useEffect(() => {
		if (toastEntry.autoDismiss === true) {
			const timeout = toastEntry.autoDismissPeriod ?? 10;
			dismissTimeout.current = window.setTimeout(handleRemove, timeout * 1000);
		}
		return () => {
			if (dismissTimeout.current !== null) {
				window.clearTimeout(dismissTimeout.current);
			}
		};
	});

	const handleRemove = (): void => {
		toast.removeToastEntry(toastEntryId);
	};

	const renderIcon = (): ReactNode => {
		switch (toastEntry.level) {
			case ToastLevel.INFO:
				return (<Icon name={IconIdentifier.INFO} size={IconSize.MEDIUM} />);
			case ToastLevel.SUCCESS:
				return (<Icon name={IconIdentifier.CHECK} size={IconSize.MEDIUM} />);
			case ToastLevel.WARNING:
				return (<Icon name={IconIdentifier.WARNING} size={IconSize.MEDIUM} />);
		}
	};

	const scale = 1 - stackPosition * 0.03;
	const toastStyle: React.CSSProperties = {
		transform: 'translateY(' + (stackPosition * -5) + 'px) scale(' + scale + ')',
		filter: 'blur(' + (stackPosition * 0.2) + 'px)',
		zIndex: 100 - stackPosition
	};

	return (
		<section className="toast" ref={ref} style={toastStyle} onClick={handleRemove}>
			<span className={`toast__icon ${buildCssClassNameFromEnumValue(toastEntry.level, 'toast__icon')}`}>
				{renderIcon()}
			</span>
			<span className="toast__caption">{toastEntry.label}</span>
		</section>
	);

});
