export enum ToastLevel {
	INFO = 'INFO',
	SUCCESS = 'SUCCESS',
	WARNING = 'WARNING'
}

export type ToastEntryId = string;

export type ToastEntry = {
	label: string,
	level: ToastLevel,
	autoDismiss?: boolean,
	autoDismissPeriod?: number
};
