import { ButtonHTMLAttributes, ReactNode } from 'react';

import { buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import { SubmissionStatus } from './ButtonPrimary.enums';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';
import { SpinnerDots } from '../spinner-dots/SpinnerDots';
import { SpinnerDotsTheme } from '../spinner-dots/SpinnerDots.enums';

import './ButtonPrimary.scss';

export type ButtonPrimaryProps = {
	label: string,
	submissionStatus?: SubmissionStatus
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function ButtonPrimary(props: ButtonPrimaryProps) {
	const { type = 'button', label, submissionStatus = SubmissionStatus.IDLE, ...rest } = props;

	const renderLabel = (): ReactNode => {
		switch (submissionStatus) {
			case SubmissionStatus.PENDING:
				return (
					<>
						<span className="button-primary__label--hidden">{label}</span>
						<SpinnerDots theme={SpinnerDotsTheme.LIGHT} />
					</>
				);
			case SubmissionStatus.DONE:
				return (
					<>
						<span className="button-primary__label--hidden">{label}</span>
						<Icon name={IconIdentifier.CHECK} />
					</>
				);
			default:
				return (
					<span className="button-primary__label">{label}</span>
				);
		}
	};

	return (
		<button
			type={type}
			className={`button-primary ${buildCssClassNameFromEnumValue(submissionStatus, 'button-primary')}`}
			{...rest}
		>
			{renderLabel()}
		</button>
	);
}
