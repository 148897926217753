import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from '../icon/Icon';
import { IconIdentifier, IconSize } from '../icon/Icon.enums';

import './NavigationSubItem.scss';

export type NavigationSubItemProps = {
	label: string,
	icon: IconIdentifier,
	to?: string,
	onClick?: () => void
};

export function NavigationSubItem(props: NavigationSubItemProps) {

	const navigate = useNavigate();
	const { to, onClick, label, icon } = props;

	const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		if (onClick !== undefined) {
			onClick();
		}
		if (to !== undefined) {
			navigate(to);
		}
	};

	return (
		<a href={to ?? ''} onClick={handleClick} className="navigation-sub-item">
			<span className="navigation-sub-item__icon">
				<Icon size={IconSize.MEDIUM} name={icon} />
			</span>
			<span className="navigation-sub-item__label">
				{label}
			</span>
		</a>
	);

}
